<template>
  <div>
    <b-sidebar
      id="ddDetailPerformanceSidebar"
      v-model="isShow"
      :title="performanceData.time | formatDateTimeTz(timezone)"
      shadow
      backdrop
      width="400px"
    >
      <div class="px-1 py-1">
        <table class="table table-sm table-borderless">
          <tbody>
            <tr>
              <th>Avg. CPU</th>
              <td>{{ performanceData.cpuPercentageAvg }}<span v-if="performanceData.cpuPercentageAvg">%</span></td>
            </tr>
            <tr>
              <th>Max. CPU</th>
              <td>{{ performanceData.cpuPercentageMax }}<span v-if="performanceData.cpuPercentageMax">%</span></td>
            </tr>
            <tr>
              <th>Max. CPU</th>
              <td>{{ performanceData.cpuPercentageMax }}<span v-if="performanceData.cpuPercentageMax">%</span></td>
            </tr>
            <tr>
              <th>Max. Disk</th>
              <td>{{ performanceData.diskPercentageMax }}<span v-if="performanceData.diskPercentageMax">%</span></td>
            </tr>
            <tr>
              <th>State</th>
              <td>{{ performanceData.state }}</td>
            </tr>
            <tr>
              <th>Network Replication Pre Out</th>
              <td>{{ performanceData.nwReplPreOut | prettyBytes({binary: true}) }}<span v-if="performanceData.nwReplPreOut">/s</span></td>
            </tr>
            <tr>
              <th>Network Replication Pre In</th>
              <td>{{ performanceData.nwReplPreIn | prettyBytes({binary: true}) }}<span v-if="performanceData.nwReplPreIn">/s</span></td>
            </tr>
            <tr>
              <th>Network Replication Out</th>
              <td>{{ performanceData.nwReplOut | prettyBytes({binary: true}) }}<span v-if="performanceData.nwReplOut">/s</span></td>
            </tr>
            <tr>
              <th>Network Replication In</th>
              <td>{{ performanceData.nwReplIn | prettyBytes({binary: true}) }}<span v-if="performanceData.nwReplIn">/s</span></td>
            </tr>
            <tr>
              <th>Network Read</th>
              <td>{{ performanceData.nwRead | prettyBytes({binary: true}) }}<span v-if="performanceData.nwRead">/s</span></td>
            </tr>
            <tr>
              <th>Network Write</th>
              <td>{{ performanceData.nwWrite | prettyBytes({binary: true}) }}<span v-if="performanceData.nwWrite">/s</span></td>
            </tr>
            <tr>
              <th>Streams Replication In</th>
              <td>{{ performanceData.streamsReplIn }}</td>
            </tr>
            <tr>
              <th>Streams Replication Out</th>
              <td>{{ performanceData.streamsReplOut }}</td>
            </tr>
            <tr>
              <th>Streams Read</th>
              <td>{{ performanceData.streamsRead }}</td>
            </tr>
            <tr>
              <th>Streams Write</th>
              <td>{{ performanceData.streamsWrite }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-sidebar>
  </div>
</template>

<script>

import { BSidebar } from 'bootstrap-vue'

export default {
  components: {
    BSidebar,
  },
  props: {
    performanceData: {
      type: Object,
      default: () => {},
    },
    timezone: {
      type: String,
      default: 'Etc/UTC',
    },
  },
  data() {
    return {
      isShow: false,
    }
  },
  methods: {
    show() {
      this.isShow = true
    },
  },
}

</script>
