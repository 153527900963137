<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-form inline>
            <b-form-group
              label="Time range"
              label-sr-only
            >
              <date-range-picker
                v-model="timeRange"
                :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days', 'last60Days', 'last90Days', 'last180Days', 'last365Days']"
                :max-selectable-days="365"
              />

              <template v-slot:description>
                <div
                  v-b-popover.hover.bottom="`If time interval is ${maxDaysForDetailedData} days or less, detailed data points with a resolution of 10 minutes are displayed. For larger time intervals, the data points are aggregated to the max. daily value.`"
                  title="Data points resolution"
                >
                  <feather-icon icon="HelpCircleIcon" />

                  <span
                    v-if="useDetailedPerformanceData"
                    class="align-middle"
                  >
                    Detailed data points
                  </span>
                  <span
                    v-else
                    class="align-middle"
                  >
                    Aggregated data points
                  </span>
                </div>
              </template>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="compactMode"
              >
                Compact mode
              </b-form-checkbox>
            </b-form-group></b-form>

        </b-card>
      </b-col>
    </b-row>

    <b-card>
      <apexchart
        type="line"
        :options="chartOptions.cpuDiskChartOptions"
        :series="[cpuAvgSeries, cpuMaxSeries, diskMaxSeries, cleaningSeries]"
        @click="chartClickHandler"
      />
    </b-card>

    <b-card>
      <apexchart
        type="line"
        :options="chartOptions.networkReadWriteChartOptions"
        :series="[nwReadSeries, nwWriteSeries]"
        @click="chartClickHandler"
      />
    </b-card>

    <b-card>
      <apexchart
        type="line"
        :options="chartOptions.networkPreReplicationChartOptions"
        :series="[nwReplPreOutSeries, nwReplPreInSeries]"
        @click="chartClickHandler"
      />
    </b-card>

    <b-card>
      <apexchart
        type="line"
        :options="chartOptions.networkReplicationInOutChartOptions"
        :series="[nwReplInSeries, nwReplOutSeries]"
        @click="chartClickHandler"
      />
    </b-card>

    <b-card>
      <apexchart
        type="line"
        :options="chartOptions.streamsChartOptions"
        :series="[streamsReplicationInSeries, streamsReplicationOutSeries, streamsReadSeries, streamsWriteSeries]"
        @click="chartClickHandler"
      />
    </b-card>

    <DataDomainDetailPerformanceSidebar
      ref="performanceSidebar"
      :performance-data="selectedPerformanceData"
      :timezone="asup.timezone"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormCheckbox, VBPopover, BForm, BFormGroup,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import DataDomainService from '@/service/datadomain.service'
import moment from '@/libs/moment'

import DataDomainDetailPerformanceSidebar from './components/DataDomainDetailPerformanceSidebar.vue'

export default {
  components: {
    DateRangePicker,
    BRow,
    BCol,
    BCard,
    apexchart: VueApexCharts,
    BFormCheckbox,
    BForm,
    BFormGroup,
    DataDomainDetailPerformanceSidebar,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      compactMode: false,
      chartHeightNormalMode: 350,
      chartHeightCompactMode: 150,
      maxDaysForDetailedData: 30,
      timeRange: {
        range: 'last3Days',
      },
      statistic: [],
      selectedPerformanceData: {},
      chartOptions: {
        cpuDiskChartOptions: {
          chart: {
            id: 'cpuDiskChart',
            group: 'performance',
            type: 'line',
            height: 350,
            zoom: {
              autoScaleYaxis: true,
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          stroke: {
            show: true,
            curve: ['straight', 'straight', 'straight', 'stepline'],
            lineCap: 'butt',
            width: 1.5,
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
          },
          title: {
            text: 'CPU / Disk Performance',
            align: 'left',
          },
          yaxis: {
            seriesName: 'Avg. CPU',
            labels: {
              formatter(val) {
                return `${(val).toFixed(0)}%`
              },
            },
            title: {
              text: 'Percentage %',
            },
            min: 0,
            max: 100,
            minWidth: 0,
          },
          xaxis: {
            type: 'datetime',
          },
          tooltip: {
            shared: true,
            y: {
              // eslint-disable-next-line no-unused-vars
              formatter(val, config) {
                // seriesIndex 3 ==> Cleaning
                if (config.seriesIndex === 3) {
                  if (val === 100) {
                    return 'Yes'
                  }

                  return 'No'
                }

                if (val) {
                  return `${(val).toFixed(0)}%`
                }

                return null
              },
            },
            x: {
              formatter(val) {
                return moment.utc(val).format('L LT')
              },
            },
          },
        },
        networkPreReplicationChartOptions: {
          chart: {
            id: 'networkPreReplicationChart',
            group: 'performance',
            type: 'line',
            stacked: false,
            height: 350,
            zoom: {
              autoScaleYaxis: true,
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          stroke: {
            show: true,
            curve: 'straight',
            lineCap: 'butt',
            width: 1.5,
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
          },
          title: {
            text: 'Network Pre Replication In/Out',
            align: 'left',
          },
          yaxis: {
            labels: {
              formatter(val) {
                return `${(val).toFixed(0)} MiB/s`
              },
              minWidth: 0,
            },
            title: {
              text: 'Speed',
            },
          },
          xaxis: {
            type: 'datetime',
          },
          tooltip: {
            shared: true,
            y: {
              formatter(val) {
                if (val) {
                  return `${(val).toFixed(0)} MiB/s`
                }
                return null
              },
            },
            x: {
              formatter(val) {
                return moment.utc(val).format('L LT')
              },
            },
          },
        },
        networkReadWriteChartOptions: {
          chart: {
            id: 'networkReadWriteChart',
            group: 'performance',
            type: 'line',
            stacked: false,
            height: 350,
            zoom: {
              autoScaleYaxis: true,
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          stroke: {
            show: true,
            curve: 'straight',
            lineCap: 'butt',
            width: 1.5,
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
          },
          title: {
            text: 'Network Read/Write',
            align: 'left',
          },
          yaxis: {
            labels: {
              formatter(val) {
                return `${(val).toFixed(0)} MiB/s`
              },
              minWidth: 0,
            },
            title: {
              text: 'Speed',
            },
          },
          xaxis: {
            type: 'datetime',
          },
          tooltip: {
            shared: true,
            y: {
              formatter(val) {
                if (val) {
                  return `${(val).toFixed(0)} MiB/s`
                }
                return null
              },
            },
            x: {
              formatter(val) {
                return moment.utc(val).format('L LT')
              },
            },
          },
        },
        networkReplicationInOutChartOptions: {
          chart: {
            id: 'networkReplicationInOutChart',
            group: 'performance',
            type: 'line',
            stacked: false,
            height: 350,
            zoom: {
              autoScaleYaxis: true,
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          stroke: {
            show: true,
            curve: 'straight',
            lineCap: 'butt',
            width: 1.5,
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
          },
          title: {
            text: 'Network Replication In/Out',
            align: 'left',
          },
          yaxis: {
            labels: {
              formatter(val) {
                return `${(val).toFixed(0)} MiB/s`
              },
              minWidth: 0,
            },
            title: {
              text: 'Speed',
            },
          },
          xaxis: {
            type: 'datetime',
          },
          tooltip: {
            shared: true,
            y: {
              formatter(val) {
                if (val) {
                  return `${(val).toFixed(0)} MiB/s`
                }
                return null
              },
            },
            x: {
              formatter(val) {
                return moment.utc(val).format('L LT')
              },
            },
          },
        },
        streamsChartOptions: {
          chart: {
            id: 'streamsChart',
            group: 'performance',
            type: 'line',
            stacked: false,
            height: 350,
            zoom: {
              autoScaleYaxis: true,
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          stroke: {
            show: true,
            curve: 'stepline',
            lineCap: 'butt',
            width: 1.5,
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
          },
          title: {
            text: 'Streams',
            align: 'left',
          },
          yaxis: {
            labels: {
              formatter(val) {
                return `${(val).toFixed(0)}`
              },
              minWidth: 0,
            },
            title: {
              text: 'Number of Streams',
            },
          },
          xaxis: {
            type: 'datetime',
          },
          tooltip: {
            shared: true,
            y: {
              formatter(val) {
                if (val) {
                  return `${(val).toFixed(0)}`
                }
                return null
              },
            },
            x: {
              formatter(val) {
                return moment.utc(val).format('L LT')
              },
            },
          },
        },
      },
    }
  },
  computed: {
    chartIds() {
      const chartIds = []
      // eslint-disable-next-line no-unused-vars
      Object.entries(this.chartOptions).forEach(([key, chartOption]) => {
        chartIds.push(chartOption.chart.id)
      })

      return chartIds
    },
    oldestStatistic() {
      return this.statistic
        .concat()
        .sort((a, b) => ((moment.utc(a.timestamp) > moment.utc((b.timestamp)) ? -1 : 1)))[0]
    },
    chartHeight() {
      if (this.compactMode === true) {
        return this.chartHeightCompactMode
      }

      return this.chartHeightNormalMode
    },
    useDetailedPerformanceData() {
      /* Detailed performance data (datapoints all 10 minutes) is only available for up to X days timerange */
      return moment(this.timeRange.endDate).diff(this.timeRange.startDate, 'days') <= this.maxDaysForDetailedData
    },
    performanceData() {
      if (this.useDetailedPerformanceData) {
        return this.statistic.flatMap(x => x.performances).sort((a, b) => (moment.utc(a.time) > moment.utc(b.time) ? 1 : -1))
      }

      /* PerformanceMax data (> X day timerange) has no time property, so we use the time property of the statistic entry itself (for every day) */
      const result = []
      for (let i = 0; i < this.statistic.length; i += 1) {
        const data = this.statistic[i].performanceMax

        if (data) {
          data.time = this.statistic[i].timestamp

          result.push(data)
        }
      }

      return result.sort((a, b) => (moment.utc(a.time) > moment.utc(b.time) ? 1 : -1))
    },
    cpuAvgSeries() {
      return {
        name: 'Avg. CPU',
        data: this.performanceData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.cpuPercentageAvg]),
      }
    },
    cpuMaxSeries() {
      return {
        name: 'Max. CPU',
        data: this.performanceData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.cpuPercentageMax]),
      }
    },
    diskMaxSeries() {
      return {
        name: 'Max. Disk',
        data: this.performanceData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.diskPercentageMax]),
      }
    },
    cleaningSeries() {
      return {
        name: 'Cleaning',
        data: this.performanceData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.hasCleanState === true ? 100 : 0]),
      }
    },
    nwReplPreOutSeries() {
      return {
        name: 'Network Pre Replication Out (MiB/s)',
        data: this.performanceData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.nwReplPreOut / 1024 / 1024]), // Byte to Mib
      }
    },
    nwReplPreInSeries() {
      return {
        name: 'Network Pre Replication In (MiB/s)',
        data: this.performanceData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.nwReplPreIn / 1024 / 1024]), // Byte to Mib
      }
    },
    nwReadSeries() {
      return {
        name: 'Network Read (MiB/s)',
        data: this.performanceData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.nwRead / 1024 / 1024]), // Byte to Mib
      }
    },
    nwWriteSeries() {
      return {
        name: 'Network Write (MiB/s)',
        data: this.performanceData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.nwWrite / 1024 / 1024]), // Byte to Mib
      }
    },
    nwReplInSeries() {
      return {
        name: 'Network Replication In (MiB/s)',
        data: this.performanceData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.nwReplIn / 1024 / 1024]), // Byte to Mib
      }
    },
    nwReplOutSeries() {
      return {
        name: 'Network Replication Out (MiB/s)',
        data: this.performanceData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.nwReplOut / 1024 / 1024]), // Byte to Mib
      }
    },
    streamsReplicationInSeries() {
      return {
        name: 'Streams Replication In',
        data: this.performanceData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.streamsReplIn]),
      }
    },
    streamsReplicationOutSeries() {
      return {
        name: 'Streams Replication Out',
        data: this.performanceData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.streamsReplOut]),
      }
    },
    streamsReadSeries() {
      return {
        name: 'Streams Read',
        data: this.performanceData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.streamsRead]),
      }
    },
    streamsWriteSeries() {
      return {
        name: 'Streams Write',
        data: this.performanceData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.streamsWrite]),
      }
    },
  },
  watch: {
    timeRange(val) {
      this.loadStatistic(val)
      this.updateCharts()
    },
    compactMode() {
      this.updateCharts()
    },
  },
  mounted() {
    this.loadStatistic()
  },
  methods: {
    adjustByTimezone(dateTime) {
      const offset = this.$moment.tz.zone(this.asup.timezone).utcOffset(dateTime)
      return dateTime.clone().subtract(offset, 'minutes')
    },
    updateCharts() {
      for (let i = 0; i < this.chartIds.length; i += 1) {
        const { opts } = this.$apexcharts.getChartByID(this.chartIds[i])

        // Update height
        opts.chart = this.$apexcharts.merge(opts, {
          height: this.chartHeight,
        })

        for (let j = 0; j < opts.yaxis.length; j += 1) {
        // Update all Y axis tick amount to prevent overlapping labels
          opts.yaxis[j] = this.$apexcharts.merge(opts.yaxis[j], {
            tickAmount: this.compactMode === true ? 2 : 10,
            title: {
              style: {
                fontSize: this.compactMode === true ? '8px' : '12px',
              },
            },
          })
        }

        let xAxisMax = moment(this.timeRange.endDate).endOf('day')
        if (this.oldestStatistic) {
          const os = this.oldestStatistic
            .performances
            .sort((a, b) => ((moment.utc(a.time) > moment.utc((b.time)) ? -1 : 1)))[0].time

          xAxisMax = this.adjustByTimezone(this.$moment.utc(os))
        }

        // Update X axis with new time range
        opts.xaxis = this.$apexcharts.merge(opts.xaxis, {
          min: this.adjustByTimezone(moment(this.timeRange.startDate)).startOf('day').valueOf(),
          max: xAxisMax.valueOf(),
        })

        this.$apexcharts.exec(this.chartIds[i], 'updateOptions', opts)
      }
    },
    loadStatistic() {
      const includeFields = []

      /*
       Performance data (datapoint all 10 minutes) is only available for max. X days timerange.
       If more than 7 days are selected, we use PerformanceMax data which aggregates all 10minute datapoints to one datapoint for every day
       */
      if (this.useDetailedPerformanceData) {
        includeFields.push('Performances')
      } else {
        includeFields.push('PerformanceMax')
      }

      /*
        Performance data is stored in child list of statistic data. Performance data of e.g. yesterday may be contained in statistics of today.
        So we need to query statistic data one day before and after the range
        */
      DataDomainService.getStatisticListAsync(this.asup.id, {
        from: moment(this.timeRange.startDate).subtract(1, 'days').startOf('day').format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).add(1, 'days').endOf('day').format('YYYY-MM-DD'),
        include: includeFields,
        maxResultCount: 1000,
      }, { disableTenantFilter: true })
        .then(result => {
          this.statistic = []
          const processedDays = {}
          for (let i = 0; i < result.items.length; i += 1) {
            const day = moment(result.items[i].timestamp).startOf('day').toISOString()
            if (!processedDays[day]) {
              this.statistic.push(result.items[i])
              processedDays[day] = true
            }
          }

          this.updateCharts()
        })
    },
    // eslint-disable-next-line no-unused-vars
    chartClickHandler(event, chartContext, config) {
      if (chartContext.zoomPanSelection.startX !== 0) { // check if chart is not in zoomPan mode
        if (config.dataPointIndex !== -1) {
          this.selectedPerformanceData = this.performanceData[config.dataPointIndex]
          this.$refs.performanceSidebar.show()
        }
      }
    },
  },
}

</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
